import { debounce } from '@github/mini-throttle'
import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
// eslint-disable-next-line import/no-named-as-default
import Sortable, { MultiDrag } from 'sortablejs'

Sortable.mount(new MultiDrag())

export class TranscriptionAssistantController extends Controller {
  static targets = [
    'newForm',
    'editForm',
    'statement', // 追加された要素へのスクロールに使用
    'statementList', // for Sortable
  ]

  declare readonly newFormTarget: HTMLFormElement
  declare readonly editFormTargets: readonly HTMLFormElement[]
  declare readonly statementListTarget: HTMLDivElement
  declare readonly statementTargets: readonly HTMLElement[]
  declare isAutoScrollEnabled: boolean
  declare sortable: Sortable
  declare selected: Set<HTMLElement>

  connect = (): void => {
    // NOTE: 画面表示のタイミングでは書き起こしの要素への自動スクロールをしたくないので時間差で有効にしている
    this.isAutoScrollEnabled = false
    setTimeout(() => {
      this.isAutoScrollEnabled = true
    }, 1000)

    this.selected = new Set()
    this.sortable = new Sortable(this.statementListTarget, {
      animation: 300,
      filter: 'textarea',
      preventOnFilter: false,
      onStart: () => {
        this.isAutoScrollEnabled = false
      },
      onEnd: () => {
        this.isAutoScrollEnabled = true
      },
      onUpdate: this.handleMove,
      // 自動スクロールの調整
      forceFallback: true,
      forceAutoScrollFallback: true,
      scrollSensitivity: 100,
      scrollSpeed: 15,
      // 複数同時に移動(要素の選択の動作は微妙なので `handleCardClick` で制御している)
      multiDrag: true,
      onSelect: (event) => {
        Sortable.utils.deselect(event.item)
      },
      onDeselect: (event) => {
        this.selected.delete(event.item)
      },
    })

    // `useCapture: true` としないと react-select のクリックを正しく判定できない場合がある
    this.element.addEventListener('click', this.autoCloseForm, { capture: true })
  }

  disconnect = (): void => {
    this.sortable.destroy()
    this.element.removeEventListener('click', this.autoCloseForm)
  }

  statementTargetConnected = (element: HTMLElement): void => {
    element.querySelector('a')?.addEventListener('click', this.handleCardClick)
    this.debouncedScrollToElement(element)
  }

  statementTargetDisconnected = (element: HTMLElement): void => {
    element.querySelector('a')?.removeEventListener('click', this.handleCardClick)
  }

  // 質問と発言の紐づけ(紐づけのみ)
  associateToStatement = (event: Event, overwriteContent: boolean = false): void => {
    const { interviewItemGid } = (event.currentTarget as HTMLButtonElement).dataset
    if (!interviewItemGid) {
      console.debug('interviewItemGid is undefined.', event.currentTarget)
      return
    }

    const form = this.currentForm()
    const container = form.querySelector<HTMLDivElement>('.js-interview-item-gid-container')
    const submitter = form.querySelector<HTMLInputElement>('input[name="refresh"]')
    if (!container || !submitter) return

    while (container.firstChild) {
      container.firstChild.remove()
    }
    container.append(this.createHiddenTag('interview_transcription_statement[interview_item_gids][]', interviewItemGid))
    if (overwriteContent) {
      container.append(this.createHiddenTag('overwrite_content', '1'))
    }
    form.requestSubmit(submitter)
  }

  // 質問と発言の紐づけ(入力内容を上書きする)
  associateAndCopyToStatement = (event: Event): void => {
    const form = this.currentForm()
    const textarea = form.querySelector<HTMLTextAreaElement>('textarea')
    if (!textarea) return

    if (textarea.value.length === 0 || window.confirm('入力中の内容は上書きされます。よろしいですか？')) {
      this.associateToStatement(event, true)
    }
  }

  private currentForm = (): HTMLFormElement => {
    return this.editFormTargets[0] || this.newFormTarget
  }

  private createHiddenTag = (name: string, value: string): HTMLInputElement => {
    const hiddenTag = document.createElement('input')
    hiddenTag.type = 'hidden'
    hiddenTag.name = name
    hiddenTag.value = value
    return hiddenTag
  }

  private autoCloseForm = (event: Event) => {
    const { target } = event
    if (!(target instanceof HTMLElement)) return
    if (target.closest('button')) return
    if (target.closest('div[id^="react-select-"]')) return

    const form = target.closest('form')
    for (const editForm of this.editFormTargets) {
      if (editForm !== form) {
        // 現在アクティブなフォーム以外は閉じる対応
        // とくにバリデーションが必要な箇所ではないため、「閉じる = 保存」のためサブミットしている
        editForm.requestSubmit()
      }
    }
  }

  private scrollToElement = (element: HTMLElement): void => {
    if (!this.isAutoScrollEnabled) return
    if (this.editFormTargets.length > 0) return

    console.debug('scroll to', element)
    element.scrollIntoView({ block: 'nearest' })
  }
  private debouncedScrollToElement = debounce(this.scrollToElement, 200)

  private handleCardClick = (e: MouseEvent) => {
    if (!e.shiftKey) {
      this.clearSelected()
      return
    }

    e.preventDefault()
    if (this.selected.size > 1) {
      this.clearSelected()
    }
    this.select((e.target as HTMLElement).closest('turbo-frame') as HTMLElement)
  }

  private select = (item: HTMLElement): void => {
    if (this.selected.size === 0) {
      Sortable.utils.select(item)
      this.selected.add(item)
      return
    }

    const lastIndex = this.statementTargets.indexOf([...this.selected][0])
    const currentIndex = this.statementTargets.indexOf(item)
    const statements = this.statementTargets.slice(
      Math.min(lastIndex, currentIndex),
      Math.max(lastIndex, currentIndex) + 1,
    )
    for (const statement of statements) {
      Sortable.utils.select(statement)
      this.selected.add(statement)
    }
  }

  private clearSelected = (): void => {
    for (const statement of this.statementTargets) {
      Sortable.utils.deselect(statement)
    }
    this.selected.clear()
  }

  private handleMove = async ({
    item,
    newIndex,
    oldIndex,
    items,
    newIndicies,
  }: Sortable.SortableEvent): Promise<void> => {
    if (newIndex === undefined || oldIndex === undefined) return

    // 複数移動
    if (items.length > 1) {
      this.clearSelected()
      const targets = [...newIndicies].reverse()
      const minIndex = Math.min(...targets.map((target) => target.index))
      for (const target of targets) {
        await this.updateStatementPosition(target.multiDragElement, newIndex > oldIndex ? target.index : minIndex)
      }
      return
    }

    // 単体移動
    console.debug('move', { item, oldIndex, newIndex })
    await this.updateStatementPosition(item, newIndex)
  }

  private updateStatementPosition = async (item: HTMLElement, newIndex: number): Promise<void> => {
    await fetch(item.dataset.moveUrl!, {
      method: 'PATCH',
      body: JSON.stringify({ position: newIndex + 1 }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()!,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
  }
}
