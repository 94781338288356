import { Controller } from '@hotwired/stimulus'

export class ScheduleController extends Controller {
  static targets = ['baseDate', 'currentDate']

  declare readonly hasBaseDateTarget: boolean
  declare readonly hasCurrentDateTarget: boolean
  declare readonly baseDateTarget: HTMLTableCellElement
  declare readonly currentDateTarget: HTMLTableCellElement

  connect = () => {
    if (!this.hasBaseDateTarget || !this.hasCurrentDateTarget) return

    this.element.scrollLeft = this.currentDateTarget.offsetLeft - this.baseDateTarget.offsetLeft
  }
}
